@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "isans";
  src: url("./assets/fonts/IRANSANSExpanded.eot?#") format("eot"),
    /* IE6–8 */ url("./assets/fonts/IRANSANSExpanded.woff2") format("woff2"),
    /* Chrome36+, Opera24+*/ url("./assets/fonts/IRANSANSExpanded.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  font-weight: normal;
}
.font-isans {
  font-family: isans !important;
}
.t-right{
  text-align: right;
}
.font-25{
  font-size: 25px;
}
.w-full{
  width: 100% !important;
}
.danger{
  font: 9px isans;
  color: red;
  margin-top: 0.5rem;
}
.p-description{
text-align: right;
width: 100%;
direction: rtl;
font-size: 12px;
}
.title{
  width: 100%;
text-align: center;
direction: rtl;

}