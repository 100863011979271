.contact{
    flex-direction: column ;
    .contact-from {
        margin-top: 2rem;
        width: 100%;
        margin-top: 2rem;
width: 100%;
display: flex;
flex-wrap: wrap;
direction: rtl;
justify-content: space-between;
        .form-control {
            width: 45%;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            color: var(--gray-color);

            input,textarea{
                padding: 10px;
                border-radius: 10px;
                outline: none;
                border: none;
                font: 10px isans !important;
                resize: none;
               &:focus{
                outline: var(--secondary-color) solid 2px;
                }
            }
            
        }
        div{
            width: 100%;
display: flex;
justify-content: center;

.btn{
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    background: var(--secondary-color);
    color: white;
    font-size: 11px;
    cursor: pointer;
}
        }
      
    }
}