.app__navbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background: rgba(255,255,255 , 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255,255,255, 0.18);
    position: fixed;
    z-index: 2;
}
.app__navbar-logo{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img{
        @media screen and (min-width : 2000px) {
            width: 180px;
            height: 40px;
        }
    }
}
.app__navbar-links{
    direction: rtl;
    font-family: isans;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    li{
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;

        div{
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
        }
        a{
            color: var(--gray-color);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease-in-out;


            &:hover{
                color: var(--secondary-color);
            }
        }
        &:hover{
            div{
                background: var(--secondary-color);
            }
        }
    }

@media screen and (max-width: 900px) {
    display: none;
}

}

.app__navbar-menu{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);


    svg{
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }

    div{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        pad: 1rem;
        width: 80%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background: url('../../assets/bgWhite.png') #fff repeat;
        background-size: cover;

        box-shadow: 0 0 20px rgba(168,168,168 , 0.15);


        svg{
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            margin:  2rem;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li{
                margin: 1rem;

                a{
                    text-decoration: none;
                    text-transform: uppercase;
                    color: var(--gray-color);
                    font-weight: 500;
                    font-size: 1rem;
                    transition: all 0.3s ease-in-out;


                    &:hover{
                        color: var(--secondary-color);
                    }
                }
            }
        }

        @media screen and (min-width:900px) {
            display: none;
        }
    }
    @media screen and (min-width:900px) {
        display: none;
    }
}

.mobile-menu{
    align-items: flex-end !important;
}
.logo{
    width: 50px;
}